<template>
  <div>
    <div>
      <b-row class="mx-3">
        <b-col
          md="12"
          style="text-align: center"
        >
          <h2>
            Solicitudes enviadas
          </h2>
        </b-col>
        <b-col
          md="12"
          class="mt-3"
          style="text-align: center"
        >
          <h5 class="mb-3">
            Seleccione la forma de subir sus comprobantes
          </h5>
        </b-col>
      </b-row>
    </div>
    <b-card>
      <div>
        <div
          v-if="receipts.length==0"
          class="d-flex justify-content-center"
        >
          <h3>
            Aún no han registrado solicitudes
          </h3>
        </div>
        <!-- table -->
        <vue-good-table
          v-else
          ref="MyTable"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <div
            slot="emptystate"
            class="text-center my-5"
          >
            <h3
              v-if="receipts.length>0"
            >
              Su búsqueda no coincide
            </h3>
            <b-overlay
              id="overlay-background"
              :show="show"
              rounded="lg"
              variant="bg-light"
              opacity="1.00"
              no-wrap
              no-fade
              class="mt-5"
            >
              <h3
                v-if="receipts.length==0"
              >
                Aún no han registrado solicitudes
              </h3>
            </b-overlay>
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'documentType'">
              {{ parseDoc(props.row.documentType) }}
            </span>
            <!-- Column: Status Date -->
            <span v-else-if="props.column.field === 'differenceDay'">
              <b-badge :variant="statusVariant(props.row.dayStatus)">
                {{ getStatus(props.row.differenceDay) }}
              </b-badge>
            </span>
            <!-- Column: Status Request -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.reqStatus)">
                {{ getStatusReq(props.row.status) }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <div style="display: flex; justify-content: center">
                  <div>
                    <b-button
                      variant="primary"
                      @click="openModal(props.row.obs)"
                    >
                      Ver
                    </b-button>
                  </div>
                </div>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Mostrando grupos de
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} registro(s) </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <b-row
          v-if="loaded && receipts.length > 0"
          class="mt-2 mx-1 flex-column"
        >
          <p class="my-0">
            FEL → Factura Electrónica
          </p>
          <p class="my-0">
            LET → Letra
          </p>
          <p class="mb-1">
            RHE → Recibo por honorario electrónico
          </p>
        </b-row>
        <b-modal
          v-model="modalShow"
          ok-only
          ok-title="Aceptar"
          centered
          title="Motivos"
        >
          <span> {{ modalText }} </span>
        </b-modal>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BBadge, BPagination, BFormSelect, BButton, BCard, BOverlay, BRow, BCol, BModal, VBModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'

export default {
  components: {
    VueGoodTable,
    BOverlay,
    BBadge,
    BPagination,
    BFormSelect,
    BButton,
    BCard,
    BRow,
    BCol,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      show: true,
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      userRuc: JSON.parse(localStorage.getItem('userData')).ruc,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Mi cliente',
          field: 'payerName',
        },
        {
          label: 'Fecha registro',
          field: 'createdAt',
          tdClass: 'text-center',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          formatFn: this.dateFormat,
        },
        {
          label: 'Documento',
          field: 'documentType',
        },
        {
          label: 'Número',
          field: 'documentNumber',
        },
        {
          label: 'Importe pago',
          field: 'originalAmount',
          tdClass: 'text-right',
          type: 'number',
          formatFn: this.moneyFormat,
        },
        {
          label: 'Fecha pago',
          field: 'payingDate',
          tdClass: 'text-center',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          formatFn: this.dateFormat,
        },
        {
          label: 'Plazo',
          field: 'differenceDay',
          type: 'number',
        },
        {
          label: 'Estado',
          field: 'status',
          type: 'number',
        },
        {
          label: 'Motivo',
          field: 'action',
        },
      ],
      rows: [],
      payers: [],
      receipts: [],
      selectedRows: [],
      isSend: false,
      modalShow: false,
      modalText: '',
      loaded: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        current      : 'light-primary',
        approved : 'light-success',
        rejected     : 'light-danger',
        notApproved     : 'light-warning',
        applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    useJwt.getMySentReceipts({
      email: this.userEmail,
      ruc: this.userRuc,
    }).then(res => {
      const values = res.data.receipts

      for (let i = 0; i < values.length; i += 1) {
        values[i].createdAt = this.normalizeDate(values[i].createdAt)
        values[i].differenceDay = Math.floor((new Date(values[i].payingDate) - new Date()) / (1000 * 3600 * 24))

        if (values[i].differenceDay > 3) {
          values[i].dayStatus = 'current'
        } else if (values[i].differenceDay >= 0) {
          values[i].dayStatus = 'warning'
        } else {
          values[i].dayStatus = 'rejected'
        }

        switch (values[i].status) {
          case 1: // rechazado
            values[i].reqStatus = 'rejected'
            break
          case 2: // pendiente
            values[i].reqStatus = 'notApproved'
            break
          case 3: // aprobado
            values[i].reqStatus = 'approved'
            break
          default:
            break
        }

        values[i].payerName = values[i].payerData[0].businessName
      }

      this.rows = values
      this.receipts = this.rows
      this.show = false
      this.loaded = true
    })
  },
  methods: {
    dateFormat(value) {
      return value ? value.split('-').reverse().join('/') : '-'
    },
    moneyFormat(money) {
      return !Number.isNaN(Number.parseFloat(money)) ? Number.parseFloat(money).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-'
    },
    normalizeDate(date) {
      const newDate = new Date(date)
      newDate.setTime(newDate.getTime() + 5 * 60 * 60 * 1000)
      const dateArr = []
      dateArr.push(newDate.getFullYear().toString(), (newDate.getMonth() + 1).toString().padStart(2, '0'), newDate.getDate().toString().padStart(2, '0'))
      return dateArr.join('-')
    },
    getStatus(status) {
      let label = ''
      let finish

      if (status >= 0) {
        finish = '0'
      } else {
        finish = '1'
      }

      switch (finish) {
        case '0':
          label = `${status} día(s)`
          break
        case '1':
          label = 'Vencido'
          break
        default:
          break
      }
      return label
    },
    getStatusReq(status) {
      let label = ''

      switch (status) {
        case 0:
          label = 'Rechazada'
          break
        case 1:
          label = 'Pendiente'
          break
        case 2:
          label = 'Aprobada'
          break
        default:
          break
      }
      return label
    },
    openModal(text) {
      this.modalShow = !this.modalShow
      this.modalText = text === undefined ? 'No hay observaciones' : text
    },
    parseDoc(document) {
      switch (document) {
        case 'Factura electronica':
          return 'FEL'
        case 'Recibo por Honorarios Electrónico':
          return 'RHE'
        default:
          return 'LET'
      }
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}

</style>
